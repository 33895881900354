import * as React from "react"
// import { Link } from "gatsby"

import { Container, Row, Col  } from 'react-bootstrap'

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Container className="singleCol">
      <h1 className="text-center my-4 mt-5">Museum Exhibits</h1>
    </Container>

    <Container>
    <h2 className="text-center">On Rotation</h2>
      <div className="brick-red-bg mb-3 mb-md-3">
        <Row className="align-items-center">
          <Col xl={5} className="pb-3 px-3 p-md-5">
            <Container>
            <h2>The Georgia Negro</h2>
            <p>Painted in 1946 by Eugene A. Montgomery</p>

            </Container>
          </Col>
          <Col xl={7} className="text-center align-items-center">
            <div class="ratio ratio-16x9">
            <iframe src="https://player.vimeo.com/video/922035224?h=10bb79c96c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"  title="The Georgia Negro"></iframe>
            </div>
          </Col>
        </Row>
      </div>
    

    <hr />
    <h2 className="text-center">Ongoing Exhibitions</h2>
      <div className="brick-red-bg my-3 mb-md-5">
        <Row className="align-items-center">
          <Col lg={{ order: 'first' }} xs={{ order: 'last' }} className="text-center">
            <StaticImage
              src="../images/heroes-sheroes-pandhandle.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Heroes and Sheroes"
            />
          </Col>
          <Col xl={5} className="pb-3 px-3 p-md-5 d-md-flex align-items-center align-self-center h-100">
            
            <Container>
              <h2>A Tribute to the NAACP Heroes and Sheroes</h2>
              <p>(Lower Level Orientation Gallery)</p>
              <p>
              The mission of the Ralph Mark Gilbert Civil Rights Museum is to tell the story of Savannah’s Civil Rights Movement. The “Heroes and Sheroes” Room on the Museum’s Lower Level tells that story. This room highlights the stories of the NAACP presidents, the women leadership of the Movement, the NAACP Lawyers and the Chatham County Crusade for Voters leadership.
              </p>
              {/* <p>
                “The course of events following the arrest of our Negro youth at downtown lunch counters, beginning with Wednesday afternoon, March 16, 1960, is a clear indication that the Savannah Negro was not satisfied with things as they were and many of them were willing to do their very best to bring Freedom and Justice to their home tow. It was an unselfish, Christian adventure. We fought hard, although we knew that many who did nothing to help and others who openly opposed the fight for freedom would in the end benefit most from the fruits of our labors.”<br /> 
                <strong>Westley Wallace Law</strong>
              </p>
              <p>
                <em>Singing Freedom Songs, Savannah, Georgia,</em> 1963” photo by Frederick C. Baldwin from <em>Civil Rights Series</em>. This iconic scene captured by Baldwin became a recurring one in cities across America during the 1960s.
              </p>
              <p>
                Aberjhani<br />
                Poet, Author, Artist
              </p> */}
            </Container>
          </Col>
          
        </Row>
      </div>
    </Container>

    <Container>
      <div className="brick-red-bg mb-3 mb-md-5">
      <h2 className="text-center pt-4">NAACP “Freedom Fighter” Earl T. Shinhoster - “<strong>Peace and Power</strong>”</h2>
      <p className="text-center">(Shinhoster Learning Center, Second Floor)</p>
      <p className="text-center">
              <em>(1950-2000) Civil Rights Advocate, National NAACP Executive</em>
            </p>
        <Row className="align-items-center">
          <Col xl={5} className="pb-3 px-4 align-items-center align-self-center h-100">
            <Container>
            {/* <h3>Free & Open to the Public <br />
            Sun., Feb 19. 1pm – 4 pm</h3>

            <h4>Afternoon at the Ralph Mark Gilbert Civil Rights Museum</h4> */}
            
            
            <p>         
              Earl T. Shinhoster’s growth into a passionate defender of human liberties began with roots deeply embedded in Savannah’s civil rights movement. He was nourished by the dynamic legacy of Ralph Mark Gilbert and by the progressive leadership of W.W. Law. As this exhibit illustrates, the life example he demonstrated is one which continues to provide empowering hope and inspiration.
            </p>
            <p>
              Aberjhani <br />
              Poet, Author, Artist
            </p>
            </Container>
          </Col>
          <Col xl={7} className="text-center">
            <StaticImage
              src="../images/exhibits/naacp.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Museum Exterior"
            />
          </Col>
        </Row>
      </div>
    </Container>

    <Container>
      <div className="brick-red-bg mb-3 mb-md-5">
        <Row className="align-items-center">
          <Col xl={5} className="pb-3 px-3 p-md-5">
            <Container>
            {/* <h3>Free & Open to the Public <br />
            Sun., Feb 19. 1pm – 4 pm</h3>

            <h4>Afternoon at the Ralph Mark Gilbert Civil Rights Museum</h4> */}
            <h2>“The Meeting” Room</h2>
            <p>(Second Floor, Classroom 1)</p>
            <p>
              In 1865, Field Order #15 was issued by General Tecumseh Sherman in Savannah, Georgia. The order, also known as “Forty Acres and A Mule”, granted land and the opportunity for Education for newly freed people. The Museum presents, a moving representation, of this story through a larger than life painting. It represents “The Meeting” Sherman had with twenty black ministers some of them newly freed when General Sherman arrived.
            </p>
            <p>
              Visit the Ralph Mark Gilbert Civil Rights Museum hear the history and see the “one of its kind” painting of Sherman meeting with the ministers in Savannah. 
            </p>

            <h3 >"The Meeting"</h3>
      <div className="ratio ratio-16x9">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/qL6j2jlBREg?si=RCRVh4tSXNUyNq4Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
 

            </Container>
          </Col>
          <Col xl={7} className="text-center align-items-center">
            <StaticImage
              src="../images/the-meeting-room.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="The Meeting Room"
            />
          </Col>
        </Row>
      </div>
    </Container>


    <Container>
      <div className="brick-red-bg mb-3 mb-md-5">
        <Row className="align-items-center">
          <Col xl={5} className="pb-3 px-3 p-md-5">
            <Container>
            {/* <h3>Free & Open to the Public <br />
            Sun., Feb 19. 1pm – 4 pm</h3>

            <h4>Afternoon at the Ralph Mark Gilbert Civil Rights Museum</h4> */}
            <h2>West Broad Street: Black Business Empire</h2>
            <p>(Second Floor Gallery)</p>
            <p>
            Blacks settled on the “edges of the city” during urban slavery and remained there after freedom in 1865. They were skilled laborers who worked for themselves. This may explain how, by the 1930s, a predominantly Black business district developed along West Broad. There were barber shops, movie theaters, pressing (or cleaning and tailoring) shops, pharmacies, confectionaries, and much more. It was like Savannah’s own “Black Wall Street.”
            </p>
            <p>
              In the early 1960s, while civil rights advocates fought to desegregate Savannah, West Broad Street underwent some major changes. Union Station, an architectural centerpiece and railroad gateway to Savannah located on West Broad was demolished in 1963. Black-owned businesses which were not closed or torn down no longer thrived. However, what remained is the building built as the Wage Earners Savings and Loan Bank in 1914, later became the Guaranty Life Insurance Company and the Atlanta Life Insurance Company and today houses the Ralph Mark Gilbert Civil Rights Museum.
            </p>
            <p>
              The West Broad Street Room is to remind all of this <strong>Black Business Empire</strong>. 
            </p>
            <p>
              In 1991, the street was renamed Martin Luther King, Jr. Boulevard.
            </p>

            <p>
              Aberjhani <br />
              Poet, Author, Artist
            </p>
 

            </Container>
          </Col>
          <Col xl={7} className="text-center align-items-center">
            <StaticImage
              src="../images/exhibits/west-broad-street.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Museum Exterior"
            />
          </Col>
        </Row>
      </div>
    </Container>

    

    

      
  </Layout>
)

export const Head = () => <Seo title="Museum Exhibits" />

export default SecondPage